import React from 'react'
// Import Swiper React components
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';


// Import Swiper styles

import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/scrollbar/scrollbar.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import './swiper.scss'; // core Swiper

const SliderContainer = (props) => {


  let photos = props.photos

  
  return (
    <>
    <div style={{display:'block',width:'100%'}}>
    <div style={{margin:'auto'}}>
    <h2 style={{textAlign:'center',marginTop:'50px'}} className="subtitle is-size-4">Galeria:</h2>
    <div style={{marginBottom:'50px',maxWidth:'100%'}}>
    <Swiper
      // install Swiper modules
      //onSwiper={(swiper) => //console.log(swiper)}
      modules={[Navigation, Pagination, A11y, Autoplay]}
      slidesPerView={1}
      spaceBetween={20}
      navigation={true}
      autoplay={true}
      loop={true}
      pagination={{ clickable: true }}
      breakpoints={{
      120: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      640: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      850: {
        slidesPerView: 1,
        spaceBetween: 40
      }
    }}
    // onSlideChange={() => console.log('slide change')}
    >
    {photos.map((el, i, isActive) => {
     return <SwiperSlide key={el+i}>
     {({ isNext }) => (
     <div style={{padding:'5%',margin:'0px 20px',textAlign:'center'}}>
       <img className={isNext ? 'natives' : 'natives-blur'} width="100%" loading="lazy" height="auto" src={photos[i]} alt="Rexer - gallery" style={{minWidth:'250px',maxWidth:'100%',marginBottom:'0px'}} />
     </div>
     )}

     </SwiperSlide>;
   })}
    <br />
    </Swiper>
    </div>
    </div>
    </div>
    </>
  );
};

export default SliderContainer
