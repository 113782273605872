import React from "react";
import { Link } from "gatsby";

class PPostOut extends React.Component {
  render() {
    let posts = this.props.posts;

    return (
      <>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <Link
              className={`portfo pbox box ${node.frontmatter.category}`}
              key={title}
              to={`/${node.fields.slug}`}
              style={{
                margin: "3%",
                minWidth: "300px",
                width: "27%",
                padding: "20px",
                border: "1px solid #00ff00",
                borderRadius: "10px"
              }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ borderRadius: "0px 5px", width: "100%" }}
                  alt={node.frontmatter.title}
                  loading="lazy"
                  src={node.frontmatter.image}
                />
              </div>
              <div key={node.fields.slug}>
                <h3 className="is-size-4" style={{ minHeight: "160px" }}>
                  <span style={{ boxShadow: `none` }}>{title}</span>
                  <br />
                  <sub
                    style={{
                      backgroundColor: "#00ff00",
                      color: "white",
                      padding: "2px 5px"
                    }}
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.category
                    }}
                  />
                </h3>
                <br />
              </div>
            </Link>
          );
        })}
      </>
    );
  }
}

export default PPostOut;
