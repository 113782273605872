import React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PpostOut from "../components/ppostOut";
import SliderContainer from "../components/sliderContainer.js";
import { gsap } from "gsap";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import MapContainer from "../components/mapcontainer";
import Share from "../components/Share";
import { rhythm } from "../utils/typography";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";

class PortfolioPostTemplate extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 0, duration: 5 });
  }
  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;

    let posts = this.props.data.allMdx.edges;

    let filterPosts = [];

    posts.forEach((item, i) => {
      if (item.node.frontmatter.title !== post.frontmatter.title) {
        filterPosts.push(item);
      }
    });

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div style={{ padding: "3em" }}>
          <h1
            style={{
              borderLeft: "5px solid #00ff00",
              fontWeight: "600",
              textTransform: "uppercase",
              paddingLeft: "10px",
              marginTop: "20px",
              fontSize: "1.5em"
            }}
          >
            {post.frontmatter.title}
          </h1>
          <h2
            style={{
              textTransform: "uppercase",
              marginTop: "15px",
              fontSize: ".9em"
            }}
          >
            <b style={{ color: "#00ff00" }}>&bull;</b>{" "}
            {post.frontmatter.category}
          </h2>
          {/* <sub
          style={{padding:'5px 10px',
            backgroundColor:'#111',color:'#00ff00',
            boxShadow:'5px 10px #00ff00',fontSize:'.8em'
          }}>{post.frontmatter.date}</sub> */}
        </div>
        <div className="columns" style={{ width: "100%", margin: "0" }}>
          <div
            className="column"
            id="a2"
            style={{
              backgroundImage: "url(" + post.frontmatter.image + ")",
              height: "450px",
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          >
            {/*<img id="a2" className="p-m-img" style={{width:'100%',height:'450px'}} src={post.frontmatter.image} alt={post.frontmatter.title} />*/}
            <br />

            <br />
            <br />
          </div>

          <div id="a1" className="column" style={{ padding: "0" }}>
            <MapContainer
              description={post.frontmatter.description}
              adress={post.frontmatter.adress}
              cat={post.frontmatter.category}
              lat={post.frontmatter.lat}
              long={post.frontmatter.long}
              title={post.frontmatter.title}
            />
          </div>
        </div>

        <div
          className="columns"
          style={{
            padding: "2.5%",
            backgroundColor: "#f6f6f6",
            width: "100%",
            textAlign: "center",
            alignItems: "center"
          }}
        >
          <div className="column">
            <h3
              style={{
                display: `block`,
                fontSize: "1.2em"
              }}
            >
              Czas realizacji:
              <br />
            </h3>
            <br />
            <p
              style={{
                fontSize: ".9em",
                backgroundColor: "black",
                boxShadow: "5px 5px #00ff00",
                color: "white",
                padding: "10px",
                margin: "10px"
              }}
            >
              {post.frontmatter.description}
            </p>
            <br />
          </div>
          <div className="column">
            <h3
              style={{
                display: `block`,
                fontSize: "1.2em"
              }}
            >
              Inwestor:
              <br />
            </h3>
            <br />
            <p
              style={{
                fontSize: ".9em",
                backgroundColor: "black",
                boxShadow: "5px 5px #00ff00",
                color: "white",
                padding: "10px",
                margin: "10px"
              }}
            >
              {post.frontmatter.investor}
            </p>
            <br />
          </div>
          <div className="column">
            <h3
              style={{
                display: `block`,
                fontSize: "1.2em"
              }}
            >
              {" "}
              Zamawiający:
              <br />
            </h3>
            <br />
            <p
              style={{
                fontSize: ".9em",
                backgroundColor: "black",
                boxShadow: "5px 5px #00ff00",
                color: "white",
                padding: "10px",
                margin: "10px"
              }}
            >
              {post.frontmatter.client}
            </p>
            <br />
          </div>
          <div className="column">
            <h3
              style={{
                display: `block`,
                fontSize: "1.2em"
              }}
            >
              Adres inwestycji:
              <br />
            </h3>
            <br />
            <p
              style={{
                fontSize: ".9em",
                backgroundColor: "black",
                boxShadow: "5px 5px #00ff00",
                color: "white",
                padding: "10px",
                margin: "10px"
              }}
            >
              {post.frontmatter.adress}
            </p>
            <br />
          </div>
        </div>
        <div className="nopad" style={{ padding: "3em" }}>
          <div className="columns">
            <div id="render" className="column">
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
            {post.frontmatter.gallery ? (
              <div
                className="column gall"
                style={{ maxWidth: "500px", minWidth: "50%" }}
              >
                <SliderContainer photos={post.frontmatter.gallery} />
              </div>
            ) : null}
          </div>
          {/*<hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />
        <br /><br />
        <Share
          title={post.frontmatter.title}
          slug={post.slug}
          excerpt={post.frontmatter.description}
          siteUrl="https://rexer.pl"
          pathPrefix="/realizacje/"
        />*/}
        </div>
        <br />
        <div style={{ padding: "5%", backgroundColor: "#00ff00" }}>
          <h2 className="is-size-4 title">Pozostałe realizacje:</h2>
          <div
            id="scroll-wrapper"
            className="rest"
            style={{
              overflowX: "scroll",
              display: "flex",
              flexWrap: "no-wrap"
            }}
          >
            <PpostOut posts={filterPosts} />
          </div>
          <br />
        </div>
        <Newsletter />
        <CTA />
      </Layout>
    );
  }
}

export default PortfolioPostTemplate;

export const ppageQuery = graphql`
  query PortfolioPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        templateKey
        date(formatString: "DD. MM. YYYY")
        language
        category
        image
        description
        lat
        long
        adress
        client
        investor
        gallery
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          language: { eq: "Polish" }
          templateKey: { eq: "portfolio-page" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            image
            category
            description
            templateKey
          }
        }
      }
    }
  }
`;
